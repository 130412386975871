import { Component, OnInit, Inject, Input } from '@angular/core';
import { interval, ReplaySubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NbDialogService } from '@nebular/theme';
import { NbDialogRef } from '@nebular/theme';
import { AppService } from '../../../../global/services/app.service';

@Component({
  selector: 'table-view-dialog',
  templateUrl: './table-view-dialog.component.html',
  styleUrls: ['./table-view-dialog.component.scss']
})
export class TableViewDialogComponent implements OnInit {
    
  @Input() rowdata: any = null;

  public rowdataArr: any = [];
  constructor(
    public appService: AppService,
    public translate: TranslateService,
    protected ref: NbDialogRef<TableViewDialogComponent>
  ) {
    
  }
  
  ngOnInit() {
    // console.log('rowdata=>',(this.rowdata),this.rowdata['address']);
    if(this.rowdata){
      for(let k in this.rowdata){
        console.log(k,'=>',this.rowdata[k]);
        this.rowdataArr.push({
          key:k,
          val: this.rowdata[k]
        });
      }
    }
    
    
  }
 
  ngOnDestroy() {
  }

  cancel() {
    this.ref.close({'cancelbtn': true,'yesbtn':false});
  }
  submit() {
    this.ref.close({'cancelbtn': false,'yesbtn':true});
  }
}
