<div>
    <!-- min-width: 1000px; -->
    <nb-card style="max-width: 1100px; max-height: 600px; min-width: 400px; min-height: 220px;">
        <nb-card-header>Table View data</nb-card-header>
        <nb-card-body style=" overflow-y: scroll;margin: 1rem 0rem;">
            <div class="row" *ngIf="rowdata">

                <div class=" col-12 col-md-12 col-lg-6 d-flex mb-2 value" *ngFor="let eachObj of rowdataArr">
                    <div class="key w-50">{{appService.allColumns[eachObj.key].title}}: </div>{{eachObj.val ? eachObj.val : '-'}}
                </div>
                <!-- <div class=" col-12 col-md-12 col-lg-6 d-flex mb-2 value" *ngIf="rowdata.groupId">
                    <div class="key w-50">{{appService.allColumns.groupId.title}}: </div>{{rowdata.groupId}}
                </div> -->

            </div>
        </nb-card-body>
        <nb-card-footer class="card-footer">
            <!-- <button class="cancel" nbButton matRipple status="danger" (click)="cancel()">Cancel</button> -->
            <button nbButton matRipple status="success" class="success" (click)="submit()">Ok</button>
        </nb-card-footer>
    </nb-card>
</div>