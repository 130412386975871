import { Component, OnInit, Inject, Input } from '@angular/core';
import { interval, ReplaySubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NbDialogService } from '@nebular/theme';
import { NbDialogRef } from '@nebular/theme';
import { AppService } from '../../../global/services/app.service';



@Component({
  selector: 'loader-dialog',
  templateUrl: './loader-dialog.component.html',
  styleUrls: ['./loader-dialog.component.scss']
})
export class LoaderDialogComponent implements OnInit {
    
  @Input() message: any = null;

  public headers: any = [];

  constructor(
    public appService: AppService,
    public translate: TranslateService,
    protected ref: NbDialogRef<LoaderDialogComponent>
  ) {

    this.appService.isLoadingObservable.subscribe((isLoading)=>{
      // console.log('dialogLoader1=>',isLoading)
      if(!isLoading){
        this.ref.close({'cancelbtn': false,'yesbtn':true});
      }
      // 
    });

    
  }
  
  ngOnInit() {
    console.log('headers=>',this.headers);
    
  }
 
  ngOnDestroy() {
  }

  cancel() {
    this.ref.close({'cancelbtn': true,'yesbtn':false});
  }
  submit() {
    this.ref.close({'cancelbtn': false,'yesbtn':true});
  }
}
