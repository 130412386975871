

<div *ngIf="renderValue=='ROUTE'" (click)="onClick()">
    <a style="cursor: pointer; color: blue;text-decoration: underline;">Route</a>
</div>
<div *ngIf="renderValue=='LIVETRACKING'" (click)="onClick()">
    <a style="cursor: pointer; color: blue;text-decoration: underline;">Live Tracking</a>
</div>
<div *ngIf="renderValue=='CHAT'">
    <button *ngIf="showBtn" nbButton status="info" (click)="onClick()">
        <!-- {{renderValue}} -->
        <nb-icon icon="message-circle"></nb-icon>
    </button>
</div>
