<div>
    <!-- min-width: 1000px; -->
    <!-- style="max-width: 1100px; max-height: 600px; min-width: 400px; min-height: 220px;" -->
    <nb-card >
        <!-- <nb-card-header>Confirmation</nb-card-header> -->
        <nb-card-body style="padding-top:1rem" class="d-flex align-items-center flex-column justify-content-center">
            <div class="lds-dual-ring"></div>
            {{message ? message : 'Please wait loading'}}            
        </nb-card-body>
    </nb-card>
</div>