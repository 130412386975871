import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { AnalyticsService, LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject, Observable, interval } from 'rxjs';
import { RippleService } from '../../../@core/utils/ripple.service';
import { CurrentThemeService } from '../../../@core/utils/theme.service';
import { AppService } from '../../../global/services/app.service';
import { TranslateService } from '@ngx-translate/core';
import { NbLayoutDirectionService, NbLayoutDirection } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';
import { NbDialogService } from '@nebular/theme';
import { NotificationsListComponent } from '../../../pages/misc/notifications-list/notifications-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NbPopoverDirective } from '@nebular/theme';
import * as moment from 'moment';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

  private destroy$: Subject<void> = new Subject<void>();
  public materialTheme$: Observable<boolean>;
  userPictureOnly: boolean = false;
  user: any;

  public notificationsList: any = [];

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
    {
      value: 'material-light',
      name: 'Material Light',
    },
    {
      value: 'material-dark',
      name: 'Material Dark',
    },
  ];

  currentTheme = 'default';
  currentLang = 'en';

  userMenu = [];//[{ title: 'Profile', val: 'profile' }, { title: 'Log out', val: 'logout' }];
  usertag: string = 'usermenu-tag';

  pageLoaded: any;
  timeObservable: Observable<string>;
  public constructor(
    private directionService: NbLayoutDirectionService,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private rippleService: RippleService,
    private analytics: AnalyticsService,
    private currentThemeService: CurrentThemeService,
    public appService: AppService,
    public translate: TranslateService,
    public dialogService: NbDialogService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.materialTheme$ = new Observable(subscriber => {
      const themeName: string = this.currentThemeService.getCurrentTheme();

      subscriber.next(themeName.startsWith('material'));
    });
  }

  ngOnInit() {
    this.timeObservable = interval(1000).pipe( // why you need 1s interval with HH:mm time format simply update it every minute not every second.
      map(() => {
        let pageLoaded = moment(new Date()); // you need the value of now not the value of the initialized time.
        return pageLoaded.format('MMMM Do YYYY, h:mm:ss a');
      })
    );
      console.log(this.appService.userdata)
    //this.loadNotifications();

    this.userMenu = [];
    
    // if (this.appService.userdata['general']?.['profile'] == '1') {
      this.userMenu.push({ title: 'Profile', val: 'profile' });
    //}
    if(this.appService.userdata.roleId==1){
      this.userMenu.push({title : 'Change Password',val : 'change-password'});
      }
    this.userMenu.push({ title: 'Logout', val: 'logout' });

    // if (this.appService.userdata['profile']?.['subaccounts']) {
    //   this.loadSubAccounts();
    // }


    this.currentDirection = this.directionService.getDirection();

    this.directionService.onDirectionChange()
      .pipe(takeWhile(() => this.alive))
      .subscribe(newDirection => this.currentDirection = newDirection);


    this.currentTheme = this.themeService.currentTheme;

    // this.user = this.appService.userLogged ? this.appService.userdata : null;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => {
        this.currentTheme = themeName;
        this.rippleService.toggle(themeName?.startsWith('material'));
      });

    this.menuService.onItemClick()
      .pipe(filter(({ tag }) => tag === this.usertag))
      .subscribe(event => {
        console.log(event);
        if (event['item']['val'] == "profile") {
          this.router.navigate(['/pages/profile']);///pages/tools/user-settings-update
        }
        if (event['item']['val'] == "logout") {
          this.logout();
        }
        if (event['item']['val'] == "change-password") {
          this.router.navigate(['/pages/change-password']);
        }

      });
  }
  async logout() {
    let deletedialog = await this.appService.deleteDialogFn('Are you sure want to Logout?');
    console.log(deletedialog);
    if (deletedialog && deletedialog['yesbtn']) {
      this.appService.userLogoutFn();
    }

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.alive = false;
  }

  changeTheme(themeName: string) {
    this.currentThemeService.setCurrentTheme(themeName);
    this.themeService.changeTheme(themeName);

    this.materialTheme$ = new Observable(subscriber => {
      subscriber.next(this.currentThemeService.getCurrentTheme().startsWith('material'));
    });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  startSearch() {
    this.analytics.trackEvent('startSearch');
  }

  trackEmailClick() {
    this.analytics.trackEvent('clickContactEmail', 'click');
  }


  changeLanguage(ev) {
    console.log(ev);
    if (ev) {
      this.translate.use(ev);
      this.appService.selectedLanguage = ev;
      if (ev == 'ar') {
        this.toggleDirection('RTL');
      } else {
        this.toggleDirection('LTR');
      }

    }
  }


  directions = NbLayoutDirection;
  currentDirection: NbLayoutDirection;
  alive = true;
  toggleDirection(newDirection) {
    this.directionService.setDirection(newDirection);

    this.analytics.trackEvent('toggleDirection', newDirection);
  }
  ngAfterViewInit() {
    // this.toggleSidebar();
  }

  loadNotifications() {
    this.notificationsList = [];
    for (let k in this.appService.userdata.notification['notification']) {
      this.notificationsList.push({
        title: k,
        titleCode: k,
        val: this.appService.userdata.notification['notification'][k] == '1' ? true : false
      });
    }
    console.log('this.notificationsList=>', this.notificationsList);
  }
  dialogAllNotifications() {
    this.popover.hide();
    this.router.navigate(['pages/notifications-list']);
    // this.dialogService.open(NotificationsListComponent, {
    //   context: {
    //     paramsdata: '',
    //   }
    // }).onClose.subscribe(name => {
    //   // name && this.names.push(name)
    // });
  }

  public subAccountsArr: any = [];
  loadSubAccounts() {
    // multiple sub accounts if exist then create accessTokens
    if (this.appService.userdata['profile']['subaccounts']) {
      let subaccounts = this.appService.userdata['profile']['subaccounts'] ? this.appService.userdata['profile']['subaccounts'] : '';//tsemri,tsemri102,fhs
      // Split the string by comma
      let subaccountsArray = subaccounts.split(',');
      // Create a new array of objects with each item from the split data
      this.subAccountsArr = subaccountsArray.map((item) => ({ 'subaccount': item }));
      console.log('subAccountsArr=>', this.subAccountsArr);

    }
  }
  async switchAccount(subaccount) {
    // multiple sub accounts if exist then create accessToken
    let user = this.appService.userdata?.profile.user;
    let paramsdata = {
      txtacnt: subaccount,
      txtuser: user,
      language: 'en',
    }
    this.appService.apicallingMultipart('getAccessToken', paramsdata)
      .subscribe(async thisdata => {
        console.log(console.log('thisdata=>'), thisdata);
        if (thisdata['status']) {
          let result = thisdata['data'];
          console.log('login result=>', result);
          this.appService.userLogged = true;
          this.appService.userdata = {
            "accessToken": result['token'],
          };
          // let getUserAccessDetails = await this.appService.getUserAccessDetailsFn({
          //   accountName: subaccount,
          //   userName: user,
          // });
          // if (getUserAccessDetails['success']) {
            this.router.navigate(['pages/dashboard']);
            this.appService.showToast('Authentication', 'Account Changed Successfully.', 'success', 1200);
          // }

        } else {
          console.log("login response false=>", thisdata);
        }

      }, error => {
        console.log('error!!!=>', error);
        if (error) {
        }
      });


  }

}
