import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NbMomentDateModule } from '@nebular/moment';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import {MatIconModule} from '@angular/material/icon';

import {
  NbChatModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbTabsetModule,
  NbUserModule,
  NbRadioModule,
  NbSelectModule,
  NbListModule,
  NbIconModule,
  NbLayoutModule,
  NbInputModule,
  NbStepperModule,
  NbAccordionModule,
  NbButtonGroupModule,
  NbToggleModule,
  NbAutocompleteModule,
  NbTimepickerModule,
  NbSearchModule,
  NbAlertModule,
  NbSpinnerModule,
  NbProgressBarModule,
  NbBadgeModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbCalendarKitModule,
} from '@nebular/theme';
import { NgxEchartsModule } from 'ngx-echarts';
import { ThemeModule } from '../@theme/theme.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ExportAsModule } from 'ngx-export-as';

// import { DirectivesModule } from './directives/directives.module';
// import { PipesModule } from './pipes/pipes.module';
import { MaterialModule } from './helpers/material.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { interceptorProviders } from './helpers/interceptors';

// import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { ImageCropperModule } from 'ngx-image-cropper';
// import { NgxMaskModule } from 'ngx-mask';
// import { NgxSliderModule } from '@angular-slider/ngx-slider';
// import { CarouselModule } from 'ngx-owl-carousel-o';
// import { CKEditorModule } from 'ckeditor4-angular';
// import { NgxPaginationModule } from 'ngx-pagination';
// import { GoogleMapsModule } from '@angular/google-maps';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgbRatingModule,NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
// import { SelectAutocompleteModule } from 'mat-select-autocomplete';
// import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';


const global_io = [
  NbCalendarKitModule,
  RouterModule,
  NbCheckboxModule,
  NbInputModule,
  NbChatModule,
  NbDatepickerModule,
  // NbMomentDateModule,
  // NbDateFnsDateModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbTabsetModule,
  NbUserModule,
  NbRadioModule,
  NbSelectModule,
  NbListModule,
  NbIconModule,
  NbLayoutModule,
  NbStepperModule,
  NbAccordionModule,
  NbButtonGroupModule,
  NbToggleModule,
  NbAutocompleteModule,
  NbTimepickerModule,
  NbSearchModule,
  NbAlertModule,
  NbSpinnerModule,
  NbProgressBarModule,
  NbBadgeModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  

  MatIconModule,
  
  NgxEchartsModule,
  ThemeModule,
  Ng2SmartTableModule,
  ExportAsModule,

  // DirectivesModule,PipesModule,
  TranslateModule,
  MaterialModule,
  NgxMatSelectSearchModule,
  FormsModule, ReactiveFormsModule,
  NgxChartsModule,
];
@NgModule({
  imports: global_io,
  declarations: [
  ],
  exports: global_io,
  providers: [
    // { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    //interceptorProviders,
    //TranslationService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class GlobalModule {}
