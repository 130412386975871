<div>
    <!-- min-width: 1000px; -->
    <nb-card style="max-width: 1100px; max-height: 600px; min-width: 400px; min-height: 220px;">
        <nb-card-header>Confirmation</nb-card-header>
        <nb-card-body style=" overflow-y: scroll;margin: 1rem 0rem;">
            {{message ? message : 'Are You sure want to delete?'}}
        </nb-card-body>
        <nb-card-footer class="card-footer">
            <button class="cancel" nbButton matRipple status="danger" (click)="cancel()">Cancel</button>
            <button nbButton matRipple status="success" class="success" (click)="submit()">Ok</button>
        </nb-card-footer>
    </nb-card>
</div>