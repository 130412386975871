import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NbDateService } from '@nebular/theme';
import { AppService } from '../../../services/app.service';
import { ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'table-view-btn',
  templateUrl: './table-view-btn.component.html',
  styleUrls: ['./table-view-btn.component.scss']
})
export class TableViewBtnComponent implements OnInit, ViewCell {

  
  renderValue: string = 'View';

  @Input() value: string = 'View';
  @Input() rowData: any;

  public showBtn: boolean = true;

  @Output() save: EventEmitter<any> = new EventEmitter();
  constructor(
    public appService: AppService,
    public translate: TranslateService,
    protected dateService: NbDateService<Date>
  ) {
  }

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
    // console.log('renderValue=>',this.renderValue);
    // console.log('rowData=>',this.rowData);
    if(
      this.rowData.deviceHeader ||
      this.rowData.dateHeader ||
      this.rowData.durationHeader
    ){
      this.showBtn = false;
    }
  }

  onClick() {
    // console.log('rowData=>',this.rowData);
    this.save.emit(this.rowData);
  }
  
  ngOnDestroy() {
  }

}
