<div class="header-container">
  <div class="logo-container">
    <a href="#" class="sidebar-toggle" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true"
      (click)="toggleSidebar()">
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'" class="light__color"></nb-icon>
    </a>
    <div class="app__header">BSTPL</div>
    <!-- <button nbButton nbButtonRound>Rous</button> -->
    <!-- <button nbButton nbButtonRound nbUser>
      TU
    </button> -->
    
    
    <!-- <nb-user name="" title="asd" size="large">s</nb-user> -->
    <!-- <a class="logo" href="#" (click)="navigateHome()">
      <img src="assets/images/sidebar__logo.svg" />
    </a> -->
  </div>
  <!-- <nb-select status="primary" matRipple [selected]="currentTheme" (selectedChange)="changeTheme($event)"
    class="theme-select">
    <nb-option *ngFor="let theme of themes" [value]="theme.value" matRipple>{{ theme.name }}</nb-option>
  </nb-select> -->

  <!-- <nb-select *ngIf="appService.selectedLanguage" style="margin-left: 1rem;" status="primary" placeholder="Language"
    matRipple [selected]="appService.selectedLanguage" class="theme-select" (selectedChange)="changeLanguage($event)">
    <nb-select-label>
      Language: {{ appService.selectedLanguage }}
    </nb-select-label>
    <nb-option *ngFor="let eachLang of appService.languagesList" [value]="eachLang.langCode" matRipple>{{ eachLang.title
      }}</nb-option>
  </nb-select> -->
  <div  class="row" style="margin-left: 1rem;">
    <div class="user-picture initials sub-account active">
      <img style="width: 57px;" src="assets/images/bs-logo.jpg" />
    </div>
  </div>
  <div *ngIf="subAccountsArr.length>0" class="row" style="margin-left: 1rem;">
    <div class="user-picture initials sub-account {{appService.userdata?.profile?.account==item.subaccount ? 'active' : ''}}" *ngFor="let item of subAccountsArr;" (click)="switchAccount(item.subaccount)">
      <img style="width: 57px;" src="assets/images/subaccounts/{{item.subaccount}}.png" />
    </div>
  </div>
</div>

<div class="d-flex align-items-center">
  <!-- <div class="header-container">
    {{ timeObservable | async }}
  </div> -->

  <div class="header-container">
    <nb-actions size="small">
      <!-- <nb-action class="control-item github-stars">
      <span class="subtitle text" [class.text-control]="materialTheme$ | async">
        Support us:
      </span>
      <iframe src="https://ghbtns.com/github-btn.html?user=akveo&repo=ngx-admin&type=star&count=true" frameborder="0"
        scrolling="0" width="170px" height="20px">
      </iframe>
    </nb-action>
    <nb-action class="control-item downloads-count">
      <nb-icon icon="download"></nb-icon>
      <span class="subtitle number" [class.text-control]="materialTheme$ | async">
        470.000
      </span>
    </nb-action> -->

      <!-- Email -->
      <!-- <nb-action class="control-item contact-us" matRipple [matRippleUnbounded]="false" [matRippleCentered]="true">
      <a nbButton ghost href="mailto:contact@glovision.co" (click)="trackEmailClick()">
        <nb-icon icon="email-outline" pack="eva"></nb-icon>
        <span [class.text-control]="materialTheme$ | async">
          contact@glovision.co
        </span>
      </a>
    </nb-action> -->

      <!-- Search -->
      <!-- <nb-action class="control-item search">
      <nb-search type="rotate-layout" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true"
        (click)="startSearch()"></nb-search>
    </nb-action> -->

      <!-- <nb-action class="control-item email" icon="email-outline" matRipple [matRippleUnbounded]="true"
      [matRippleCentered]="true"></nb-action> -->

      <!-- dashboard stats -->
    <nb-action class="control-item notifications" icon="flag" matRipple [matRippleUnbounded]="true"
        [matRippleCentered]="true" [nbPopover]="templateRef" nbPopoverPlacement="bottom"></nb-action>
      <ng-template #templateRef>
        <nb-card *ngIf="appService.dashboardStats">
          <nb-card-header>Device Stats</nb-card-header>
          <nb-card-body class="example-items-col">
            <!-- <nb-progress-bar [value]="20"></nb-progress-bar> -->
            <div class="progress-bar-headers">
              <div *ngFor="let eachPerc of appService.dashboardStats">
                <div *ngIf="eachPerc.key != 'total'">
                  <div>{{eachPerc.name}} {{eachPerc.percentage}}% ({{eachPerc.value}})</div>
                  <nb-progress-bar [value]="eachPerc.value" class="{{eachPerc.classname}}" status="primary"></nb-progress-bar>
                </div>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </ng-template>

      <!-- Notifications -->
      <nb-action class="control-item notifications d-none" icon="car-outline" matRipple [matRippleUnbounded]="true"
        [matRippleCentered]="true" [nbPopover]="fms" >FMS</nb-action>
      <ng-template #fms>
        <nb-card>
          <nb-card-body class="example-items-col">
            <div>Vehicle Registration</div>
            <div>Driver</div>
            <div>Permit</div>
            <div>Pollution</div>
            <div>Insurance</div>
            <div>Daily Log</div>
            <div>Battery</div>
            <div>Tyre</div>
            <div>Toll</div>
            <div>Maintenance</div>
            <div>Servicing</div>
            <div>Breakdown</div>
            <div>Vehicle Accident</div>
            <div>Driver Counselling</div>
          </nb-card-body>
       
        </nb-card>
      </ng-template>
 <!-- Notifications -->
      <!-- <nb-action class="control-item notifications" icon="bell-outline" matRipple [matRippleUnbounded]="true"
        [matRippleCentered]="true" [nbPopover]="templateRef" nbPopoverPlacement="bottom"></nb-action>
      <ng-template #templateRef>
        <nb-card>
          <nb-card-body class="example-items-col">
            <div *ngFor="let eachNot of notificationsList;">
              <nb-toggle labelPosition="end" [(ngModel)]="eachNot.val" [(checked)]="eachNot.val">{{eachNot.title}}
              </nb-toggle>
            </div>
          </nb-card-body>
          <nb-card-footer>
            <div (click)="dialogAllNotifications()">View all notifications</div>
          </nb-card-footer>
        </nb-card>
      </ng-template> -->


      <!-- Username & Image -->
      <nb-action class="user-action" *nbIsGranted="['view', 'user']" matRipple [matRippleUnbounded]="false"
        [matRippleCentered]="true">
        <nb-user [nbContextMenu]="userMenu" [nbContextMenuTag]="usertag" [onlyPicture]="userPictureOnly"
        [name]="(appService.userLogged ? appService.userdata?.displayName : appService.userdata?.displayName)"
         picture="assets/images/bs-logo.jpg" >
          
        </nb-user>
      </nb-action>
    </nb-actions>
  </div>
</div>