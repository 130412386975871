import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { GlobalModule } from './global/global.module';
import { ExcelService } from './global/services/excel.service';
import { ExportDataService } from './global/services/export-data.service';

// import {} from  './global/global.module'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxSelectModule } from 'ngx-select-ex';

import {
  NbChatModule,
  NbDatepickerModule,
  NbTimepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { NbMomentDateModule } from '@nebular/moment';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { InlineSVGModule } from 'ng-inline-svg';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppService } from './global/services/app.service';

import { environment } from '../environments/environment';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
export function HttpLoaderFactory(httpClient: HttpClient) {
  // return new TranslateHttpLoader(httpClient);
  // return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cacheBuster='+ environment.cacheBusterHash);
  return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}
export function appConfigProvider(appService: AppService, translate: TranslateService) {

  console.log('initializeApp=>', environment);
  appService.environment = environment;
  return (() => {
    // console.log('initializeApp1=>', environment);
    // await appService.loadLanguagesList();

    //languages add to web...
    translate.addLangs(['en', 'ar', 'hi', 'te']);
    let langStorageval = appService.getItem(appService.APP_LANGUAGE);// ? appService.getItem(appService.APP_LANGUAGE) : 'en';
    // if (langStorageval && (langStorageval == 'ar')) {
    //   appService.dirLang = (langStorageval == 'ar') ? 'rtl' : 'ltr';
    // }

    if (langStorageval) {
      translate.use(langStorageval);
      appService.selectedLanguage = langStorageval;
    } else {
      appService.selectedLanguage = 'en';//translate['defaultLang'];
      // console.log('event=>', appService.selectedLanguage);
      translate.use(appService.selectedLanguage);
    }
    console.log('langStorageval=>', translate);
    translate.onLangChange.subscribe(async (event) => {
      // console.log('translate=>',translate);console.log('event=>', event);
      appService.selectedLanguage = event['lang'];
      appService.langData = event.translations;
      console.log('appService.langData=>', appService.langData, appService.selectedLanguage);
      console.log(appService.userLogged);
      if (appService.userLogged) {
        // let getUserAccessDetails = await appService.getUserAccessDetailsFn({
        //   accountName: appService.userdata?.profile?.account,
        //   userName: appService.userdata?.profile.user,
        // });
        // console.log('getUserAccessDetails after=>', getUserAccessDetails);
      }
      // await this.loadInitApis();
    });
    if (appService.environment.env) {
      appService.getLabels().subscribe((labels) => {
        console.log('appService.labelsConf=>', labels);
        appService.labelsConf = labels;
        appService.allColumns['deviceID']['title'] = appService.labelsConf['deviceId'];
        appService.allColumns['device']['title'] = appService.labelsConf['deviceId'];
        appService.allColumns['description']['title'] = appService.labelsConf['description'];
      });
    }

    console.log(appService.getItem(appService.USER_DETAILS));
    if (appService.getItem(appService.HAS_LOGGED_IN) && appService.getItem(appService.USER_DETAILS)) {
      console.log('hello');
      appService.userLogged = true;
      appService.userdata = JSON.parse(appService.getItem(appService.USER_DETAILS));
       appService.createMenuFeatures();
      console.log('appService.userdata=>', appService.userdata);
      // appService.loadMinDate();
      // appService.loadMaxDate();

      // appService.userdata.account['maptype']='google';
      // let script = document.createElement("script");
      // script.setAttribute("src", "https://maps.googleapis.com/maps/api/js?key=key&libraries=places&v=weekly");
      // document.getElementsByTagName("head")[0].appendChild(script);
      // console.log('LocalStorage Cache => userData =>', appService.userdata);
      // appService.selectedLanguage = appService.defaultLangObj;

    } else {
      appService.createMenuFeatures();
      //console.log("ss");
      // appService.selectedLanguage = appService.defaultLangObj;
      // console.log('selected language=>',appService.selectedLanguage);
      // await appService.loadInitApis('healthspecialist');

    }
    // if (appService.getItem(appService.APP_MENU_ITEMS)) {

    //   // leaflet, mapmyindia, else
    //   // if(appService.userdata.account['maptype']=='leaflet'){

    //   // }else if(appService.userdata.account['maptype']=='mapmyindia'){
    //   //   appService.loadMapMyIndia();
    //   // }else{
    //   // }
    // }


  });
}
export function initializeApp(appService: AppService): Promise<any> {
  return new Promise((resolve, reject) => {
    // alert('hello work');
    // Do some asynchronous stuff
    resolve({});
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    GlobalModule,
    TranslateModule.forRoot({
      // defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        // useClass: TranslationService,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbTimepickerModule.forRoot(),
    Ng2SmartTableModule,
    NgxSelectModule,
    NgxMatSelectSearchModule,
    // NbChatModule.forRoot({
    //   messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    // }),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    InlineSVGModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ExcelService, ExportDataService,
    { provide: APP_INITIALIZER, useFactory: appConfigProvider, deps: [AppService, TranslateService], multi: true },
  ]
})
export class AppModule {
}
